import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isInfoStyling: false,
		};
	}

	toggleInfo = () => {
		this.setState(
			{
				isInfoStyling: !this.state.isInfoStyling,
			},
			() => {
				return this.props.toggleInfo();
			}
		);
	};

	renderHeaderContent = () => {
		if (this.props.isSplashPage) {
			return null;
		}

		return (
			<footer>
				<span>
					<Link to="/">
					<h1>MÉNAD KESRAOUI</h1>
					</Link>
				</span>
				<span onClick={this.props.triggerInfoSection}>
					<h1>INFO</h1>
				</span>
			</footer>
		);
	};

	render() {
		return (
			<div className="footer_container">
				{this.renderHeaderContent()}
			</div>
		)
	}
}

export default Header;
