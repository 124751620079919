import React from 'react';
import Favicon from 'react-favicon';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import App from './App';
import IndexPage from "./IndexPage";
import ProjectSections from "./projects_components/ProjectSections";
import ParamProjectSections from "./projects_components/ParamProjectSections";
import Info from "./info_components/Info";
import MyProvider from '../src/myProvider'
import MyContext from './myContext'

import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Favicon url="https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1615930016/m_k/og-menad.png" />
    <MyProvider>
      <Router>
        <Switch>
          <Route exact path='/' component={IndexPage} />
          <Route exact path='/projects' component={() => {
            return <MyContext.Consumer>
              {context => (
                < App context={context} />
              )
              }
            </MyContext.Consumer>
          }} />
          <Route exact path='/:type' component={ProjectSections} />
          <Route exact path='/:type/:id' component={ParamProjectSections} />
        </Switch>
      </Router>
    </MyProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
