import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import mock_data from '../mock_data.js';
import '../App.css';

class IndexFullVideoComponent extends Component {
	renderFullVideo = (ele, index) => {
		return (
			<div>
				<video autoPlay loop muted>
					<source src={ele.videoFullSize && ele.videoFullSize.asset.url} type="video/mp4" />
				</video>
			</div>
		);
	};

	renderFullVideoCaption = (ele) => {
		return (
			<div className="selected_project_caption">
				<h1>{ele.titlePlaceholder}</h1>
			</div>
		);
	};

	render() {
		let ele = this.props.ele;
		let index = this.props.index;
		if (!this.props.ele) {
			return null;
		}
		return (
			<div className="index_video_component">
				{this.renderFullVideo(ele, index)}
				{this.renderFullVideoCaption(ele)}
			</div>
		);
	}
}

export default IndexFullVideoComponent;
