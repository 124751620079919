import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import IndexFullVideoComponent from './homepage_component/IndexFullVideoComponent';
import IndexThumbnailVideoComponent from './homepage_component/IndexThumbnailVideoComponent';
import Header from './nav_component/Header';
import Footer from './nav_component/Footer';
import Info from './info_components/Info';
import TransitionComponent from './transition/TransitionComponent';
import SplashPage from './splash_page/SplashPage';
import './App.css';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			isInfo: false,
			isSplashPage: true,
			isToggledTransition: false,
			isTransitionSection: false,
			transitionSectionCounter: 0,
			selectedSection: 'all',
			categories: [],
			mousedEnteredIndexImage: null,
			featuredProjectsData: null,
			featuredProjectsDataPrev: null,
			featuredProjectsThumbnailView: null,
			featuredProjectsFullVideoView: null,
			width: 0,
		};
	}

	componentDidMount() {
		fetch(`/.netlify/functions/getHomePage`)
		.then((result) => {
			switch (result.status) {
				case 200:
					return result.json().then((body) => {
						const { allProjects, landingPage, categories } = body.response[0];
						this.setState(
							{width: window.innerWidth},	
						);
						window.addEventListener('resize', () => {
							this.setState({
								width: window.innerWidth,
							});
						});

						const addProjectIdToAllProjects = allProjects.map((item, index) => {
							return {
								projectID: index + 1,
								...item,
							};
						});

						const test = _.shuffle(addProjectIdToAllProjects).filter((ele) => {
							return ele.featuredProject;
						});


						this.setState(
							{
								allProjectsData: _.shuffle(addProjectIdToAllProjects),
								categories: categories,
								featuredProjectsData: _.shuffle(addProjectIdToAllProjects).filter((ele) => {
									return ele.featuredProject;
								}),
								featuredTransitionsData: _.shuffle(
									addProjectIdToAllProjects.filter((ele) => {
										return ele.featuredTransitions && ele.featuredProject;
									})
								),
								splashPageData: landingPage,
							},
							() => {
								let featuredProjectsThumbnailView = this.state.featuredProjectsData.map(
									(ele, index) => {
										return (
											<IndexThumbnailVideoComponent
												resetThumbnailLoop={this.resetThumbnailLoop}
												toggleHoverIndexImage={this.toggleHoverIndexImage}
												triggerMouseLeaveIndexImage={this.triggerMouseLeaveIndexImage}
												index={index + 1}
												ele={ele}
												{...this.state}
											/>
										);
									}
								);
								// generating the featuredProjectsFullVieoView here;
								let featuredProjectsFullVideoView = this.state.featuredProjectsData.map(
									(ele, index) => {
										return <IndexFullVideoComponent index={index + 1} ele={ele} {...this.state} />;
									}
								);
								this.setState({
									featuredProjectsThumbnailView,
									featuredProjectsFullVideoView,
									isLoaded: true,
								});
							}
						);
					});
				default:
					return null;
			}
		});
	}

	toggleHoverIndexImage = (index) => {
		if (this.state.width < 900) {
			return null;
		}

		this.setState(
			{
				mousedEnteredIndexImage: index,
			},
			() => {
				return this.updateFeaturedProjectsThumbnailView(index);
			}
		);
	};

	updateFeaturedProjectsThumbnailView = (index) => {
		if (this.state.width < 900) {
			return null;
		}

		let featuredProjectsArray = document.getElementsByClassName('index_image_component_container');
		let featuredProjectsCaptionArray = document.getElementsByClassName('index_image_component_caption');
		if (index !== null) {
			Array.prototype.forEach.call(featuredProjectsArray, (ele, indexOfEle) => {
				if (indexOfEle + 1 === index) {
					ele.style.animationPlayState = 'paused';
				} else {
					ele.style.animationPlayState = 'paused';
					ele.style.visibility = 'hidden';
				}
			});
			Array.prototype.forEach.call(featuredProjectsCaptionArray, (ele, indexOfEle) => {
				ele.style.visibility = 'hidden';
			});
		} else {
			Array.prototype.forEach.call(featuredProjectsArray, (ele) => {
				ele.style.animationPlayState = 'running';
				ele.style.visibility = 'visible';
				ele.style.filter = 'none';
			});
			Array.prototype.forEach.call(featuredProjectsCaptionArray, (ele, indexOfEle) => {
				ele.style.visibility = 'visible';
			});
		}
	};

	resetThumbnailLoop = (index) => {
		if (this.state.width < 900) {
			return null;
		}

		let featuredProjectsLength = this.state.featuredProjectsData.length;
		let featuredTransitionsLength = this.state.featuredTransitionsData.length;

		if (!this.state.isToggledTransition) {
			return;
		}

		if (index === featuredProjectsLength - 1) {
			this.setState(
				{
					featuredProjectsDataPrev: this.state.featuredProjectsData,
					isTransitionSection: true,
					mousedEnteredIndexImage: null,
					transitionSectionCounter: this.state.transitionSectionCounter + 1,
				},
				() => {
					this.setState({
						featuredProjectsData: null,
					});
					if (this.state.isTransitionSection) {
						setTimeout(() => {
							this.setState({
								isTransitionSection: false,
								featuredProjectsData: _.shuffle(
									this.state.featuredProjectsDataPrev.filter((ele) => {
										return ele.featuredProject;
									})
								),
							});
						}, 2500);
					}

					if (this.state.transitionSectionCounter === featuredTransitionsLength - 1) {
						this.setState({
							transitionSectionCounter: 0,
						});
					}
				}
			);
		} else {
			return;
		}
	};

	renderSplashPage = () => {
		if (!this.state.isSplashPage) {
			return null;
		}
		return (
			 <SplashPage
			 		toggleSplashPage={this.toggleSplashPage}
					{...this.state}
				/>
		)
	};

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="loading_page">
					<h1>loading</h1>
					<div className="loading_page_bubbles">
						<span className="dot"></span>
						<span className="dot"></span>
						<span className="dot"></span>
					</div>
				</div>
			);
		}

		return (
			<main className="App homepage">
				<Link to="/projects">
				{this.renderSplashPage()}
				</Link>
			</main>
		);
	}
}

export default App;
