import React, { Component } from 'react'

import MyContext from './myContext';

class MyProvider extends Component {
    state = {
        isProjectsTransitionDisabled: false,
    };

    disableTransition = () => {
        this.setState({
            isProjectsTransitionDisabled: true
            ,
        }, () => {
            

        })
    }

    render() {
        return (
            <MyContext.Provider
                value={{
                    isProjectsTransitionDisabled: this.state.isProjectsTransitionDisabled,
                    disableProjectsTransitions: () => {
                        this.disableTransition()
                    },
                }}
            >
                {this.props.children}
            </MyContext.Provider>
        );
    }
}

export default MyProvider