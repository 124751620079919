import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import '../App.css';

class IndexThumbnailVideoComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isHover: true,
			ele: null,
			eleIndex: null,
		};
	}

	componentDidMount() {
		let ele = this.props.ele;
		let index = this.props.index;
		this.setState({
			ele: ele,
			eleIndex: index,
		});
	}

	toggleHoverIndexImage = (index) => {
		this.props.toggleHoverIndexImage(index);
	};

	resetThumbnailLoop = (index) => {
		this.props.resetThumbnailLoop(index);
	};

	renderProjectCaptionSection = (projectName) => {

		let projectNameSplited = projectName.split(' - ');
		let title = this.state.ele.type.title;

		return (
			<div className="index_image_component_caption">
				<h1>{projectName}</h1>
				<h1>{title}</h1>
			</div>
		);
	};

	renderThumbnail = () => {
		let ele = this.state.ele;
		let index = this.state.eleIndex;
		if (!ele || !index || !this.props.width) {
			return null;
		}

		let eleImg = ele.imageThumbnail.asset.url;

		let projectRoutePlaceholder = `/${ele.type.slug.current}/${ele.slug.current}`;
		const toggleVisibility = keyframes`
        0%{
          opacity:0;
          visibility: hidden
        }
         99.9% {
           opacity:0;
           visibility: hidden

         }
         100% {
           opacity:1;
           visibility: visible
         }
      `;

		const Div = styled.div`
			animation-delay: 0.5s;
			animation: ${this.props.isProjectsTransitionDisabled ? "" : toggleVisibility} ${index}s linear;
			animation-iteration-count: 1;
		`;

		let id = `videoThumbnail_${index}`;
		if (this.props.width > 600) {
			return (
				<Link to={projectRoutePlaceholder}>
					<Div
						onAnimationEnd={() => this.resetThumbnailLoop(index)}
						className="index_image_component_container"
					>
						<div id={id} className="index_image_component">
							{this.renderEleContent(ele, index)}
							{this.renderProjectCaptionSection(ele.titlePlaceholder)}
						</div>
					</Div>
				</Link>
			);
		} else {
			return (
				<Link to={projectRoutePlaceholder}>
					<div className="index_image_component_container">
						<div id={id} className="index_image_component">
							<img src={ele.imageThumbnail && ele.imageThumbnail.asset.url} />
							{this.renderProjectCaptionSection(ele.titlePlaceholder)}
						</div>
					</div>
				</Link>
			);
		}
	};


	renderEleContent = (ele, index) => {
		if(this.state.isHover){
			return (
				<video
					onMouseEnter={() => this.toggleHoverIndexImage(index)}
					onMouseLeave={() => this.toggleHoverIndexImage(null)}
					autoPlay
					loop
					muted
				>
					<source src={ele.videoThumbnail && ele.videoThumbnail.asset.url} type="video/mp4" />
				</video>
			)
		}
	}


	render() {
		return <div style={{ display: 'inline' }}>{this.renderThumbnail()}</div>;
	}
}

export default IndexThumbnailVideoComponent;
