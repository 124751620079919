const mock_data = module.exports = {
  general: {
    route: "/about",
    generalInfo: {
      name: "Ménad Kesraoui",
      title: "(Director of photography)",
      bio: "Ménad Kesraoui est un concepteur visuel, directeur artistique et chef-décorateur basé à Montréal, Canada. Hétéroclite, délicat mais tout en cohérence, son travail témoigne d’un sens précis et personnel de l’esthétique, sachant se mettre au service du projet. Naviguant avec aisance dans plusieurs univers et priorisant un esprit de collaboration, Ménad souhaite avant tout créer des identités visuelles et animer les espaces. Accessoirement, elle adore les fleurs et les grands danois.",
      wordsToHighLight: ["concepteur", "délicat", "identités", "visuelles"],
      contact: {
        email: "m_k@m_k.com",
        phone: "+1 (514) 455-1333",
        instagram: "@mk"
      }
    }
  },
  splashPage: {
    firstHeadline: "Menad Kesraoui",
    secondHeadline: "menadkesraoui[at]gmail.com",
    videoLink: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1596773967/m_k/video/W2.mp4"
  },
  projects: {
    route: "/projects",
    entries: [
      {
        projectID: 1,
        featuredProject: true,
        featuredTransitions: true,
        type: "film",
        slug: "dead-obies-runaway",
        titlePlaceholder: "Dead Obies - Runaway",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/B3.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032549/m_k/video/M5_1.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032549/m_k/video/M5_1.mp4",
        vimeoId: "431522966",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
          "Montage - Louis Chevalier-Dagenais",
          "Animation 3D - Fréderic Duquette",
          "Colorisation - Ménad Kesraoui"
        ],
      },
      {
        projectID: 2,
        featuredProject: true,
        featuredTransitions: false,
        type: "commercial",
        slug: "dead-obies-runaway",
        titlePlaceholder: "Dead Obies - Runaway",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/B3.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        vimeoId: "431522966",
        textPlaceholder: [
          "Directed by: Ménad Kesraoui",
          "DOP: Ménad Kesraoui",
          "Colours: by: Ménad Kesraoui"
        ],
      },
      {
        projectID: 3,
        featuredProject: true,
        featuredTransitions: false,
        type: "film",
        slug: "dead-obies-runaway",
        titlePlaceholder: "Dead Obies - Runaway",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/B3.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032549/m_k/video/M5_1.mp4",
        vimeoId: "431522966",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
        ],
      },
      {
        projectID: 4,
        featuredProject: true,
        featuredTransitions: false,
        type: "film",
        slug: "dead-obies-runaway",
        titlePlaceholder: "Dead Obies - Runaway",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/B3.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032547/m_k/video/K1.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032547/m_k/video/K1.mp4",
        vimeoId: "431522966",
        textPlaceholder: [
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
          "Montage - Louis Chevalier-Dagenais",
          "Animation 3D - Fréderic Duquette",
          "Colorisation - Ménad Kesraoui"
        ],
      },
      {
        projectID: 5,
        featuredProject: true,
        featuredTransitions: false,
        type: "film",
        slug: "dead-obies-runaway",
        titlePlaceholder: "Dead Obies - Runaway",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/B3.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        vimeoId: "431522966",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
          "Montage - Louis Chevalier-Dagenais",
          "Animation 3D - Fréderic Duquette",
          "Colorisation - Ménad Kesraoui"
        ],
      },
      {
        projectID: 6,
        featuredProject: false,
        featuredTransitions: false,
        type: "film",
        slug: "dead-obies-runaway",
        titlePlaceholder: "Dead Obies - Runaway",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/B3.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        vimeoId: "431522966",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
          "Montage - Louis Chevalier-Dagenais",
          "Animation 3D - Fréderic Duquette",
          "Colorisation - Ménad Kesraoui"
        ],
      },
      {
        projectID: 7,
        featuredProject: true,
        featuredTransitions: false,
        type: "film",
        slug: "philippe-b-rouge-gorge",
        titlePlaceholder: "Philippe B - Rouge Gorge",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772746/m_k/img/A2.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032547/m_k/video/K1.mp4",
        vimeoId: "431522966",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico"
        ],
      },
      {
        projectID: 8,
        featuredProject: false,
        featuredTransitions: false,
        type: "film",
        slug: "commercial-desjardings-jeunesse",
        titlePlaceholder: "Desjardins Jeunesse",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772746/m_k/img/W2.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1596773967/m_k/video/W2.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032547/m_k/video/K1.mp4",
        vimeoId: "170899504",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
          "Montage - Louis Chevalier-Dagenais",
          "Animation 3D - Fréderic Duquette",
          "Colorisation - Ménad Kesraoui"
        ],
      },
      {
        projectID: 9,
        featuredProject: true,
        featuredTransitions: true,
        type: "music",
        slug: "mik-bone-new-york",
        titlePlaceholder: "Milk&Bone - New York",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772747/m_k/img/M4.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032542/m_k/video/M4.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        vimeoId: "170899504",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
        ],
      },
      {
        projectID: 10,
        featuredProject: true,
        featuredTransitions: true,
        type: "music",
        slug: "tumi-directors-cut",
        titlePlaceholder: "Tumi - Director’s Cut",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772736/m_k/img/M5.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032523/m_k/video/W2.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032523/m_k/video/W2.mp4",
        vimeoId: "170899504",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
        ],
      },
      {
        projectID: 11,
        featuredProject: false,
        featuredTransitions: false,
        type: "commercial",
        slug: "julie-and-the-wold-devouring-1",
        titlePlaceholder: "Julie and the Wolf - Devouring (1)",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772725/m_k/img/R1.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1596773807/m_k/video/R1.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        vimeoId: "170899504",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico",
          "Montage - Louis Chevalier-Dagenais",
          "Animation 3D - Fréderic Duquette",
          "Colorisation - Ménad Kesraoui"
        ],
      },
      {
        projectID: 12,
        featuredProject: false,
        featuredTransitions: false,
        type: "commercial",
        slug: "julie-and-the-wold-devouring-2",
        titlePlaceholder: "Julie and the Wolf - Devouring (2)",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772735/m_k/img/K4.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032532/m_k/video/K4.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        vimeoId: "170899504",
        textPlaceholder: [
          "Directed by: Ménad Kesraoui",
          "DOP: Ménad Kesraoui",
          "Colours: by: Ménad Kesraoui"
        ],
      },
      {
        projectID: 13,
        featuredProject: false,
        featuredTransitions: false,
        type: "commercial",
        slug: "julie-and-the-wold-devouring-3",
        titlePlaceholder: "Julie and the Wolf - Devouring (3)",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
        videoThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032547/m_k/video/K1.mp4",
        videoFullSize: "https://res.cloudinary.com/www-c-t-l-k-com/video/upload/v1597032552/m_k/video/A2.mp4",
        vimeoId: "170899504",
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico"
        ],
      },
      // will change this;
      {
        projectID: 14,
        featuredProject: false,
        featuredTransitions: false,
        type: "images",
        slug: "image-project-1",
        titlePlaceholder: "image project #1",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
        projectImages: [
          "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
          "https://res.cloudinary.com/dubneid9u/image/upload/v1599874670/COMPRESSED/012240200007.jpg",
          "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
          "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
        ],
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico"
        ],
      },
      {
        projectID: 15,
        featuredProject: false,
        featuredTransitions: false,
        type: "images",
        slug: "image-project-2",
        titlePlaceholder: "image project #2",
        imageThumbnail: "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
        projectImages: [
          "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
          "https://res.cloudinary.com/dubneid9u/image/upload/v1599874670/COMPRESSED/012240200007.jpg",
          "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
          "https://res.cloudinary.com/www-c-t-l-k-com/image/upload/v1596772742/m_k/img/K1.jpg",
        ],
        textPlaceholder: [
          "Réalisation - Romain F. Dubois",
          "Production - Roméo & Fils",
          "Direction Photo - Ménad Kesraoui",
          "Direction artistique - Constance Chamberland",
          "Direction de production - Émilie Mercier & Galadrielle D'Amico"
        ],
      }
    ]
  }
}
