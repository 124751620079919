import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Vimeo from '@u-wave/react-vimeo';
import Header from '../nav_component/Header';
import mock_data from '../mock_data.js';
import '../App.css';


class ProjectTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mock_data: mock_data,
			selectedProject: null,
			selectedProjectType: null,
			renderProjectDetails: false,
			isVideoPaused: false,
		};
	}


	toggleProjectDetails = () => {
		this.setState(
			{
				renderProjectDetails: !this.state.renderProjectDetails,
				isVideoPaused: !this.state.isVideoPaused,
			},
			() => {
				let project_template_video_container = [...document.getElementsByClassName("project_template_video_container")];
				let selected_projects_media_cell = [...document.getElementsByClassName("selected_projects_media_cell")];
				let selected_projects_media_cell_headline = [...document.getElementsByClassName("selected_projects_media_cell_headline")];
				let vimeo_embed = document.getElementById('vimeo_embed');

				if (this.state.renderProjectDetails) {

					this.props.handleProjectInfoToggle(true);

					document.body.style.overflow = "hidden";
					vimeo_embed.style.filter = 'blur(30px)';
					document.body.style.background = "black";
					project_template_video_container[0].style.backgroundColor = "black";

					selected_projects_media_cell.map((ele, index) => {
						ele.style.backgroundColor = "black";
					})

					if(selected_projects_media_cell_headline.length > 0){
						selected_projects_media_cell_headline[0].style.filter = 'blur(30px)';
					}

				} else {

					this.props.handleProjectInfoToggle(false);
					document.body.style.overflow = "scroll";
					vimeo_embed.style.filter = 'blur(0px)';
					document.body.style.background = "white";
					project_template_video_container[0].style.backgroundColor = "white";

					selected_projects_media_cell.map((ele, index) => {
						ele.style.backgroundColor = "white";
					})


					if(selected_projects_media_cell_headline.length > 0){
						selected_projects_media_cell_headline[0].style.filter = 'blur(0px)';
					}

				}
			}
		);
	};

	renderProjectDetails = () => {
		let selectedProject = this.props.selectedProject;

		if (this.state.renderProjectDetails) {
			let displayProjectCredits = selectedProject.textPlaceholder.map((ele, index) => {
				return <span key={index}>{ele}</span>;
			});

			let displayProjectType = () => {
				return <span>{selectedProject.type.title}</span>;
			};

			return (
				<nav
					id="project_template_details"
					className="project_template_details">
					<section>
						<div>
							<h1>Credits:</h1>
							{displayProjectCredits}
						</div>
						<div>
							<h1>Type:</h1>
							{displayProjectType()}
						</div>
						<div>
							<h1>Year:</h1>
							<span>2019</span>
						</div>
					</section>
				</nav>
			);
		}
	};

	renderToggleIcon = () => {
		if (this.state.renderProjectDetails) {
			return <span>x</span>;
		} else {
			return <span style={{ cursor: 'pointer' }}>+</span>;
		}
	};

	backToProjectCategory = () => {
		return '<';
	};

	renderDynamicStyling = () => {
		if(this.state.renderProjectDetails){
			return "white"
		}else{
			return "black"
		}
	}

	render() {
		let selectedProject = this.props.selectedProject;

		if (!selectedProject) {
			return null;
		}

		return (
			<main className="ProjectTemplate">
				<section className="project_template_video_container">
					<nav className="template_video_info_cta">
						<h1
								style={{color: this.renderDynamicStyling()}}
								onClick={this.toggleProjectDetails}>
								{this.renderToggleIcon()}
						</h1>
						<Link to="/projects">
							<h1 style={{color: this.renderDynamicStyling()}}>
								{this.backToProjectCategory()}
							</h1>
						</Link>
					</nav>
					<Vimeo
						id="vimeo_embed"
						video={selectedProject.vimeoId}
						autoplay
						paused={this.state.isVideoPaused}
					/>
				</section>
				{this.renderProjectDetails()}
			</main>
		);
	}
}

export default ProjectTemplate;
