import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import Header from "../nav_component/Header";
import mock_data from "../mock_data.js";
import "../App.css";

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: "",
      bioFrench: "",
      wordsToHighLight: [],
      contact: {},
      isLoading: false,
    };
  }

  componentDidMount() {
    fetch(`/.netlify/functions/getHomePage`).then((response) => {
      this.setState({
        isLoading: true,
      });
      switch (response.status) {
        case 200:
          return response.json().then((result) => {
            const {
              bio,
              contact,
              wordsToHighLight,
              bioFrench,
            } = result.response[0];
            return this.setState(
              {
                translation: true,
                bio: bio,
                bioFrench: bioFrench,
                contact: contact,
                wordsToHighLight,
              },
              () => {
                this.setState({ isLoading: false });
              }
            );
          });
        default:
          return null;
      }
    });
  }

  renderInfoBody = () => {
    let generalInfo = this.state.translation
      ? this.state.bio
      : this.state.bioFrench;
    let wordsToHighLight = this.state.wordsToHighLight;
    let splitToSpanAndIsolateSelectedWords = () => {
      let bioFormatted = generalInfo.split(" ").map((ele, index) => {
        if (wordsToHighLight.indexOf(ele) !== -1) {
          return (
            <div className="bio_highlighted_words">
              <span key={index}>{ele}&nbsp;</span>
            </div>
          );
        } else {
          return (
            <div>
              <span className="bio_words" key={index}>
                {ele}&nbsp;
              </span>
              &nbsp;
            </div>
          );
        }
      });
      return bioFormatted;
    };

    return (
      <section className="main_info_text_container">
        <section className="info_cta">
          <span onClick={this.props.triggerInfoSection}>X</span>
          <button
            className="__reset "
            onClick={() =>
              this.setState({
                translation: !this.state.translation,
              })
            }
          >
            <span>
              <span
                style={{
                  borderBottom: this.state.translation
                    ? "1px solid white"
                    : null,
                }}
              >
                EN
              </span>
              /
              <span
                style={{
                  borderBottom: !this.state.translation
                    ? "1px solid white "
                    : null,
                }}
              >
                FR
              </span>
            </span>
          </button>
        </section>
        <section className="main_info_text_bio">
          {splitToSpanAndIsolateSelectedWords()}
        </section>
        <section className="main_info_text_secondary">
          <section>
            <span>CANADA</span>
            <div>
              <h1>
                <a href={this.state.contact.instagramUrl} target="_blank">
                  {this.state.contact.instragram}
                </a>
              </h1>
            </div>
            <div>
              <h1>
                <a href={`mailto:${this.state.contact.email}`} target="_blank">
                  {this.state.contact.email}
                </a>
              </h1>
            </div>
            <div>
              <h1>{this.state.contact.phone}</h1>
            </div>
          </section>
          <section>
            <span>US</span>
            <div>
              {this.state.contact.usNameOfAgency && (
                <h1>{this.state.contact.usNameOfAgency}</h1>
              )}
            </div>
            <div>
              {this.state.contact.usNameOfWebsite && (
                <h1>
                  <a href={this.state.contact.usNameOfWebsite} target="_blank">
                    {this.state.contact.usNameOfWebsite}
                  </a>
                </h1>
              )}
            </div>
            <div>
              {this.state.contact.usEmailOfAgency && (
                <h1>
                  <a
                    href={`mailto:${this.state.contact.usEmailOfAgency}`}
                    target="_blank"
                  >
                    {this.state.contact.usEmailOfAgency}
                  </a>
                </h1>
              )}
            </div>
          </section>
          <section>
            <span>JAPAN</span>
            <div>
              <h1>{this.state.contact.japanNameOfAgency}</h1>
            </div>
            <h1>
              <a href="http://www.dreambear.org" target="_blank">
                www.twinbrainsfilms.com
              </a>
            </h1>
            <div>
              <h1>
                <a href={`mailto:${this.state.contact.email}`} target="_blank">
                  {this.state.contact.japanEmailOfAgency}
                </a>
              </h1>
            </div>
          </section>
        </section>
        <div className="credits">
          Digital Direction and Programming: 75-20 + Jamal Tobias
        </div>
      </section>
    );
  };

  renderInfoClosingLayer = () => {
    return <div className="main_info_closing_layer"></div>;
  };

  render() {
    return (
      <main className="info">
        {this.state.isLoading ? (
          <div className="loading_page">
            <h1>loading</h1>
            <div className="loading_page_bubbles">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </div>
          </div>
        ) : (
          <React.Fragment>{this.renderInfoBody()}</React.Fragment>
        )}
      </main>
    );
  }
}

export default Info;
