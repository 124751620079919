import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import scrollToComponent from 'react-scroll-to-component';
import Header from '../nav_component/Header';
import SelectedContentMedia from './SelectedContentMedia.js';
import mock_data from '../mock_data.js';
import '../App.css';

class ProjectSections extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mock_data: mock_data,
			selectedParam: null,
			selectedProjects: null,
			selectedProjectOnFocus: null,
			isStopScrolling: true,
			selectedContentMedia: null,
			isLoading: false,
		};

		this.refsArray = [];
	}



	componentDidMount() {

		let selectedParam = this.props.match.params.type;

		fetch(`/.netlify/functions/getProjectsByCategory?slug=${selectedParam}`).then((result) => {
			this.setState({
				isLoading: true,
			});
			switch (result.status) {
				case 200:
					return result.json().then((body) => {
						const { projects } = body.response;
						this.setState(
							{
								selectedParam: selectedParam,
								selectedProjects: projects,
							},
							() => {
								let selectedContentMedia = this.state.selectedProjects.map((ele, index) => {
									let projectRoute = `/${ele.type.slug.current}/${ele.slug.current}`;

									if(this.state.selectedParam !== "images"){
										return (
											<Link to={projectRoute}>
												<div
													ref={(ref) => {
														this.refsArray[index] = ref;
													}}
												>
													<SelectedContentMedia
														index={index}
														projectsEntries={this.state.selectedProjects}
														ele={ele}
														{...this.state}
													/>
												</div>
											</Link>
										);
									}else{
										return (
											<SelectedContentMedia
												index={index}
												projectsEntries={this.state.selectedProjects}
												ele={ele}
												{...this.state}
											/>
										);
									}
								});
								this.setState({
									selectedContentMedia,
									isLoading: false,
								});
							}
						);
					});
				default:
					return null;
			}
		});
	}


	renderSelectedContentIndex = () => {
		let selectedProjects = this.state.selectedProjects;
		if (selectedProjects !== null) {
			if (!this.state.isStopScrolling
				|| this.state.selectedParam === "images") {
				return null;
			}

			let selectedProjectsMaped = selectedProjects.map((ele, index) => {
				return (
					<div
						key={index}
						onClick={() => {
							this.refsArray[index].scrollIntoView();
							this.setState({
								selectedProjectOnFocus: index,
							});
						}}
						className="selected_projects_text_cell"
					>
						<h1>
							<span>{index + 1} - </span>
							{ele.titlePlaceholder}
						</h1>
					</div>
				);
			});
			return (
				<div className="selected_projects_text_cell_container">
					{selectedProjectsMaped}
				</div>
			);
		}
	};

	renderSelectedContentNav = () => {
		if (!this.state.isStopScrolling) {
			return null;
		}
		return (
			<div className="seleted_projects_information_container">
				<div>
					<Link to="/projects">
						<h1>BACK TO PROJECTS</h1>
					</Link>
				</div>
				<div>
					<h1>SELECTED: {this.state.selectedParam}</h1>
				</div>
			</div>
		);
	};

	renderSelectedContentMedia = () => {
		return <div className="selected_projects_media_cell_container">{this.state.selectedContentMedia}</div>;
	};


	render() {
		let selectedParam = this.state.selectedParam;
		if (!selectedParam) {
			return null;
		}

		if (this.state.isLoading) {
			return (
				<div className="loading_page">
					<h1>loading</h1>
					<div className="loading_page_bubbles">
						<span className="dot"></span>
						<span className="dot"></span>
						<span className="dot"></span>
					</div>
				</div>
			);
		}
		return (
			<main
				className="selected_projects_main_container">
				{this.renderSelectedContentIndex()}
				{this.renderSelectedContentMedia()}
				{this.renderSelectedContentNav()}
			</main>
		);
	}
}

export default ProjectSections;
