import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import scrollToComponent from "react-scroll-to-component";
import Header from "../nav_component/Header";
import SelectedContentMedia from "./SelectedContentMedia.js";
import ProjectTemplate from "./ProjectTemplate.js";
import mock_data from "../mock_data.js";
import "../App.css";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

class ParamProjectSections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mock_data: mock_data,
      selectedParam: null,
      selectedProjectSlug: null,
      selectedProjects: null,
      selectedProjectOnFocus: null,
      isStopScrolling: true,
      selectedContentMedia: null,
      isLoading: false,
      isProjectInfo: false,
    };

    this.refsArray = [];
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.handleFetch();
    }
  }

  componentDidMount() {
    this.handleFetch();
  }

  scrollTo = (element, clientRect) => {
    window.scroll({ behavior: "smooth", left: 0, top: clientRect.top });
  };

  handleFetch = () => {
    const { type, id } = this.props.match.params;
    this.setState({ selectedProjectSlug: id });
    let selectedParam = this.props.match.params.type;
    fetch(
      `/.netlify/functions/getProjectsByCategory?slug=${selectedParam}`
    ).then((result) => {
      this.setState({ isLoading: true });
      switch (result.status) {
        case 200:
          return result.json().then((body) => {
            const { projects } = body.response;
            this.setState(
              {
                selectedParam: selectedParam,
                selectedProjects: projects,
              },
              () => {
                let selectedContentMedia = this.state.selectedProjects.map(
                  (ele, index) => {
                    let projectRoute = `/${ele.type.slug.current}/${ele.slug.current}`;

                    if (ele.slug.current === this.state.selectedProjectSlug) {
                      return (
                        <div
                          id={ele.slug.current}
                          ref={(ref) => {
                            this.refsArray[index] = ref;
                          }}
                        >
                          <ProjectTemplate
                            selectedProject={ele}
                            handleProjectInfoToggle={
                              this.handleProjectInfoToggle
                            }
                          />
                        </div>
                      );
                    } else {
                      return (
                        <div
                          id={ele.slug.current}
                          ref={(ref) => {
                            this.refsArray[index] = ref;
                          }}
                        >
                          <Link to={projectRoute}>
                            <SelectedContentMedia
                              index={index}
                              projectsEntries={this.state.selectedProjects}
                              ele={ele}
                            />
                          </Link>
                        </div>
                      );
                    }
                  }
                );

                this.setState({ selectedContentMedia, isLoading: false });
              }
            );
          });
        default:
          return null;
      }
    });

    setTimeout(() => {
      let selectedProjectSlug = this.state.selectedProjectSlug;
      let targetedId = document.getElementById(selectedProjectSlug);
      let clientRect = targetedId.getBoundingClientRect();

      this.scrollTo(targetedId, clientRect);
    }, 1000);
  };

  handleProjectInfoToggle = (bool) => {
    this.setState(
      {
        isProjectInfo: bool,
      },
      () => {
        let id = "selected_projects_media_container";
        let idTexts = "seleted_projects_information_container";
        let idArray = document.getElementsByClassName(id);
        let htmlListToArray = [...idArray];

        if (this.state.isProjectInfo) {
          htmlListToArray.map((ele, index) => {
            ele.style.filter = "blur(40px)";
          });
        } else {
          htmlListToArray.map((ele, index) => {
            ele.style.filter = "blur(0)";
          });
        }
      }
    );
  };

  renderSelectedContentIndex = () => {
    let selectedProjects = this.state.selectedProjects;
    if (selectedProjects !== null) {
      if (this.state.selectedParam === "images" || this.state.isProjectInfo) {
        return null;
      }

      let selectedProjectsMaped = selectedProjects.map((ele, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              this.refsArray[index].scrollIntoView();
              this.setState({ selectedProjectOnFocus: index });
            }}
            className="selected_projects_text_cell"
          >
            <h1>
              <span>{index + 1} - </span>
              {ele.titlePlaceholder}
            </h1>
          </div>
        );
      });
      return (
        <div className="selected_projects_text_cell_container">
          {selectedProjectsMaped}
        </div>
      );
    }
  };

  renderSelectedContentNav = () => {
    if (!this.state.isStopScrolling || this.state.isProjectInfo) {
      return null;
    }

    return (
      <div className="seleted_projects_information_container">
        <div>
          <Link to="/projects">
            <h1>BACK TO PROJECTS</h1>
          </Link>
        </div>
        <div>
          <h1>SELECTED: {this.state.selectedParam}</h1>
        </div>
      </div>
    );
  };

  renderSelectedContentMedia = () => {
    return (
      <div className="selected_projects_media_cell_container">
        {this.state.selectedContentMedia}
      </div>
    );
  };

  render() {
    if (!this.state.selectedParam) {
      return null;
    }

    if (this.state.isLoading) {
      return (
        <div className="loading_page">
          <h1>loading</h1>
          <div className="loading_page_bubbles">
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      );
    }
    return (
      <main className="selected_projects_main_container">
        {this.renderSelectedContentIndex()}
        {this.renderSelectedContentMedia()}
        {this.renderSelectedContentNav()}
      </main>
    );
  }
}

export default ParamProjectSections;
