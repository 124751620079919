import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import IndexFullVideoComponent from './homepage_component/IndexFullVideoComponent';
import IndexThumbnailVideoComponent from './homepage_component/IndexThumbnailVideoComponent';
import Header from './nav_component/Header';
import Footer from './nav_component/Footer';
import Info from './info_components/Info';
import TransitionComponent from './transition/TransitionComponent';
import SplashPage from './splash_page/SplashPage';
import './App.css';
import MyContext from './myContext';

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;


class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			isInfo: false,
			isSplashPage: false,
			isToggledTransition: false,
			isTransitionSection: false,
			transitionSectionCounter: 0,
			selectedSection: 'all',
			categories: [],
			mousedEnteredIndexImage: null,
			featuredProjectsData: null,
			featuredProjectsDataPrev: null,
			featuredProjectsThumbnailView: null,
			featuredProjectsFullVideoView: null,
			width: 0,

		};
	}

	componentWillUnmount(state) {
		this.props.context.disableProjectsTransitions()
	}

	componentDidMount() {
		console.log(this.props.context)
		fetch(`/.netlify/functions/getHomePage`)
			.then((result) => {
				switch (result.status) {
					case 200:
						return result.json().then((body) => {
							const { allProjects, landingPage, categories } = body.response[0];
							this.setState(
								{ width: window.innerWidth }
							);

							window.addEventListener('resize', () => {
								this.setState({
									width: window.innerWidth,
								});
							});

							const addProjectIdToAllProjects = allProjects.map((item, index) => {
								return {
									projectID: index + 1,
									...item,
								};
							});

							this.setState(
								{
									allProjectsData: _.shuffle(addProjectIdToAllProjects),
									categories: categories,
									featuredProjectsData: _.shuffle(addProjectIdToAllProjects).filter((ele) => {
										return ele.featuredProject;
									}),
									featuredTransitionsData: _.shuffle(
										addProjectIdToAllProjects.filter((ele) => {
											return ele.featuredTransitions && ele.featuredProject;
										})
									),
								},
								() => {
									let featuredProjectsThumbnailView = this.state.featuredProjectsData.map(
										(ele, index) => {
											return (
												<IndexThumbnailVideoComponent
													isProjectsTransitionDisabled={this.props.context.isProjectsTransitionDisabled}
													resetThumbnailLoop={this.resetThumbnailLoop}
													toggleHoverIndexImage={this.toggleHoverIndexImage}
													triggerMouseLeaveIndexImage={this.triggerMouseLeaveIndexImage}
													index={index + 1}
													ele={ele}
													{...this.state}
												/>
											);
										}
									);
									// generating the featuredProjectsFullVieoView here;
									let featuredProjectsFullVideoView = this.state.featuredProjectsData.map(
										(ele, index) => {
											return <IndexFullVideoComponent index={index + 1} ele={ele} {...this.state} />;
										}
									);
									this.setState({
										featuredProjectsThumbnailView,
										featuredProjectsFullVideoView,
										isLoaded: true,
									});
								}
							);
						});
					default:
						return null;
				}
			});
	}

	toggleHoverIndexImage = (index) => {
		if (this.state.width < 900) {
			return null;
		}

		this.setState(
			{
				mousedEnteredIndexImage: index,
			},
			() => {
				return this.updateFeaturedProjectsThumbnailView(index);
			}
		);
	};

	updateFeaturedProjectsThumbnailView = (index) => {
		if (this.state.width < 900) {
			return null;
		}

		let featuredProjectsArray = document.getElementsByClassName('index_image_component_container');
		let featuredProjectsCaptionArray = document.getElementsByClassName('index_image_component_caption');
		if (index !== null) {
			Array.prototype.forEach.call(featuredProjectsArray, (ele, indexOfEle) => {
				if (indexOfEle + 1 === index) {
					ele.style.animationPlayState = 'paused';
				} else {
					ele.style.animationPlayState = 'paused';
					ele.style.visibility = 'hidden';
				}
			});
			Array.prototype.forEach.call(featuredProjectsCaptionArray, (ele, indexOfEle) => {
				ele.style.visibility = 'hidden';
			});
		} else {
			Array.prototype.forEach.call(featuredProjectsArray, (ele) => {
				ele.style.animationPlayState = 'running';
				ele.style.visibility = 'visible';
				ele.style.filter = 'none';
			});
			Array.prototype.forEach.call(featuredProjectsCaptionArray, (ele, indexOfEle) => {
				ele.style.visibility = 'visible';
			});
		}
	};

	resetThumbnailLoop = (index) => {
		let featuredProjectsLength = this.state.featuredProjectsData.length;
		let featuredTransitionsLength = this.state.featuredTransitionsData.length;

		if (!this.state.isToggledTransition) {
			return;
		}

		if (index === featuredProjectsLength - 1) {
			this.setState(
				{
					featuredProjectsDataPrev: this.state.featuredProjectsData,
					isTransitionSection: true,
					mousedEnteredIndexImage: null,
					transitionSectionCounter: this.state.transitionSectionCounter + 1,
				},
				() => {
					this.setState({
						featuredProjectsData: null,
					});
					if (this.state.isTransitionSection) {
						setTimeout(() => {
							this.setState({
								isTransitionSection: false,
								featuredProjectsData: _.shuffle(
									this.state.featuredProjectsDataPrev.filter((ele) => {
										return ele.featuredProject;
									})
								),
							});
						}, 2500);
					}

					if (this.state.transitionSectionCounter === featuredTransitionsLength - 1) {
						this.setState({
							transitionSectionCounter: 0,
						});
					}
				}
			);
		} else {
			return;
		}
	};

	toggleTransition = () => {
		this.setState(
			{
				isToggledTransition: !this.state.isToggledTransition,
				featuredProjectsDataPrev: this.state.featuredProjectsData,
				featuredProjectsData: null,
			},
			() => {
				this.setState({
					featuredProjectsData: this.state.featuredProjectsDataPrev,
				});
			}
		);
	};


	renderHeader = () => {
		if (this.state.isTransitionSection
			|| this.state.isInfo) {
			return null;
		}
		return (
			<Header
				{...this.state}
				triggerInfoSection={this.triggerInfoSection}
			/>
		);
	};

	triggerInfoSection = () => {
		this.setState({
			isInfo: !this.state.isInfo
		}, () => {

			let divId = "index_image_component_main_container"
			let indexImgMainDiv = document.getElementsByClassName(divId)[0];

			if (this.state.isInfo) {
				indexImgMainDiv.style.filter = "blur(40px)"
			} else {
				indexImgMainDiv.style.filter = "none"
			}
		})
	}

	renderFooter = () => {
		let categories = this.state.categories;
		if (!categories
			|| this.state.isInfo
			|| this.state.isTransitionSection) {
			return null;
		}
		return <Footer {...this.state} />;
	};

	renderTransitionSection = () => {
		if (!this.state.isTransitionSection) {
			return null;
		}
		return (
			 <TransitionComponent
			 	{...this.state}
			 />
		);
	};


	renderFeaturedImages = () => {
		if (!this.state.featuredProjectsThumbnailView
			|| this.state.isSplashPage
			|| !this.state.featuredProjectsData) {
			return null;
		}
		return (
			<section className="index_image_component_main_container">
				{this.state.featuredProjectsThumbnailView}
			</section>
		);
	};

	renderFullFeaturedVideos = () => {
		let mousedEnteredIndexImage = this.state.mousedEnteredIndexImage - 1;
		if (
			mousedEnteredIndexImage === null ||
			!this.state.featuredProjectsFullVideoView ||
			this.state.isSplashPage
		) {
			return null;
		}
		return (
			<section className="index_video_component_main_container">
				{this.state.featuredProjectsFullVideoView[mousedEnteredIndexImage]}
			</section>
		);
	};

	renderToggleTransition = () => {
		if (this.state.isInfo) {
			return null;
		}
		return (
			<div className="toggle_transition_cta">
				{
					this.props.context.isProjectsTransitionDisabled ? null :
					<h2 onClick={this.toggleTransition}>Transition:
						{this.renderToggleTransitionIndicator()}
					</h2>
				}

			</div>
		);
	};

	renderToggleTransitionIndicator = () => {
		if (this.state.isToggledTransition) {
			return <span>enabled</span>;
		} else {
			return <span>disabled</span>;
		}
	};

	renderInfoSection = () => {
		if (this.state.isInfo) {
			return (
				<div>
					<Info
						triggerInfoSection={this.triggerInfoSection}
					/>
					<div className="info_background_layer" />
				</div>
			)
		}
	};

	renderDynamicStyling = () => {
		if (!this.state.isInfo) {
			return "white"
		} else {
			return "black"
		}
	}

	render() {
		if (!this.state.isLoaded) {
			return (
				<div className="loading_page">
						loaded
				</div>
			);
		}

		// {this.renderToggleTransition()}


		return (
			<main
				style={{ backgroundColor: this.renderDynamicStyling() }}
				className="App homepage">
				{this.renderInfoSection()}
				{this.renderHeader()}
				{this.renderFeaturedImages()}
				{this.renderTransitionSection()}
				{this.renderFullFeaturedVideos()}
				{this.renderFooter()}
			</main>
		);
	}
}

export default App;
