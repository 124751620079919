import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import '../App.css';

class TransitionComponent extends Component {
	renderTransitionContent = () => {
		if (!this.props) {
			return null;
		}

		let selectedFeaturedTransitionProject = this.props.featuredTransitionsData[this.props.transitionSectionCounter];
		let selectedFeaturedTransitionProjectID = selectedFeaturedTransitionProject.projectID;
		let selectedFeaturedTransitionProjectType = selectedFeaturedTransitionProject.type.slug.current;
		let allProjectsDataLength = this.props.allProjectsData.length;

		console.log(selectedFeaturedTransitionProject.type.title, "here");

		return (
			<section className="transition_section">
				<nav>
					<h1>
						{selectedFeaturedTransitionProject.titlePlaceholder}
					</h1>
					<h1>
						CATEGORY: {selectedFeaturedTransitionProject.type.title}
					</h1>
				</nav>
				<video
					className="transition_video"
					autoPlay loop muted>
					<source src={selectedFeaturedTransitionProject.videoFullSize.asset.url} type="video/mp4" />
				</video>
			</section>
		);
	};
	render() {
		return <div className="index_video_component">{this.renderTransitionContent()}</div>;
	}
}

export default TransitionComponent;
