import React, { Component } from 'react';
import SelectedImgCell from "./SelectedImgCell";
import { Link } from 'react-router-dom';
import '../App.css';


class SelectedContentMedia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: null,
			isMouseClicked: false,
			url: null,
			index: null,
			imageArray: null
		};
	}

	componentDidMount() {
		this.setState({
			width: window.innerWidth,
		});

		window.addEventListener('resize', () => {
			this.setState({
				width: window.innerWidth,
			});
		});
	}

	handleImgMouseClick = (url, index, imageArray) => {


		this.setState({
			isMouseClicked: !this.state.isMouseClicked,
			url,
			index,
			imageArray
		}, () => {

			let cellContainer = [...document.getElementsByClassName("selected_projects_media_cell_container")];
			let imgContainer = [...document.getElementsByClassName("img_container")];
			let informationContainer = [...document.getElementsByClassName("seleted_projects_information_container")];

			if (this.state.isMouseClicked) {

				document.body.style.overflow = "hidden";
				cellContainer[0].style.backgroundColor = "black";
				informationContainer[0].style.display = "none"
				imgContainer.map((ele) => {
					ele.style.filter = "blur(30px)";
				})
			} else {

				document.body.style.overflow = "scroll";
				cellContainer[0].style.backgroundColor = "white";
				informationContainer[0].style.display = "flex"
				imgContainer.map((ele) => {
					ele.style.filter = "blur(0px)";
				})
			}
		})
	};

	handleSelectionMove = (direction) => {
		const { imageArray, index } = this.state;
		if (direction === "left"
			&& index !== imageArray.length - 1) {
			this.setState({
				index: index - 1,
				url: imageArray[index - 1].asset.url
			})
		}
		if (direction === "right"
			&& index !== imageArray.length - 1) {
			this.setState({
				index: index + 1,
				url: imageArray[index + 1].asset.url
			})
		}
		if (index === imageArray.length - 1) {
			this.setState({
				index: 0,
				url: imageArray[0].asset.url
			})
		}
	}

	handleDynamicStyling = () => {
		const { index } = this.state;
		if (index === 0) {
			return "hidden"
		} else {
			return "visible"
		}
	}


	displayLightBox = () => {
		const { index, imageArray } = this.state;
		if (!this.state.isMouseClicked) {
			return null;
		}
		return (
			<div className="project_lightbox">
				<div className="lightbox_header">
					<div className="close_lightbox">
						<h1 onClick={() => this.handleImgMouseClick(null, null, null)}>
							CLOSE
								</h1>
						<h1>
							{index}
							/
							{imageArray.length}
						</h1>
					</div>
				</div>
				<span className="first_arrow_lightbox">
					<span
						style={{ visibility: this.handleDynamicStyling() }}
						onClick={() => this.handleSelectionMove("left")}>{"<"}</span>
					<span onClick={() => this.handleSelectionMove("right")}>
						{">"}
					</span>
				</span>
				<img src={this.state.url} />
			</div>
		)
	}

	renderRandomStyling = () => {
		return {
			paddingLeft: `${Math.floor(Math.random() * 60) + 1}vw`
		}
	}

	renderMedia = (ele, index) => {
		if (ele.type.slug.current === 'images') {
			let imageArray = ele.projectImages;
			return (
				<div className="selected_projects_media_cell">
					<div className="selected_projects_img_container">
						{imageArray.map((item, index) => {
							const { title, medium, image, _key, yearOfPhoto } = item;
							return (
								<div key={_key} className="img_container">
									<SelectedImgCell
										medium={medium}
										yearOfPhoto={yearOfPhoto}
										title={title}
										url={image && image.asset.url}
										paddingLeft={this.renderRandomStyling()}
										handleImgMouseClick={this.handleImgMouseClick}
										index={index}
										imageArray={imageArray}
									/>
								</div>
							);
						})}
					</div>
					{this.displayLightBox()}
				</div>
			);
		} else {
			return (
				<div key={index} className="selected_projects_media_cell">
					{this.renderCorrectComponent(ele)}
					<nav>
						<div className="selected_projects_media_cell_headline">
							<h1>{ele.titlePlaceholder}</h1>
							<span>2019</span>
						</div>
					</nav>
				</div>
			);
		}
	};

	renderCorrectComponent = (ele) => {
		if (this.state.width > 600) {
			return (
				<div className="selected_projects_media_container">
					<video autoPlay loop muted>
						<source src={ele.videoThumbnail && ele.videoThumbnail.asset.url} type="video/mp4" />
					</video>
				</div>
			);
		} else {
			return (
				<div className="selected_projects_media_container">
					<img src={ele.imageThumbnail && ele.imageThumbnail.asset.url} />
				</div>
			);
		}
	};

	render() {
		let ele = this.props.ele;
		let index = this.props.index;

		if (!this.props.ele || !this.state.width) {
			return null;
		}
		return <div>{this.renderMedia(ele, index)}</div>;
	}
}

export default SelectedContentMedia;
