import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';


class SelectedImgCell extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: null,
			isMouseClicked: false
		};
	}

	render() {
		let { url, index, imageArray, paddingLeft, medium, title, yearOfPhoto } = this.props;
		return (
			<div
				className="img_container"
				style={paddingLeft}>
				<img
					src={url}
					alt={url}
				/>
				<div className="img_container_labels">
					<span>
						{title}
					</span>
					<span>
						{medium}
					</span>
					<span>
						{yearOfPhoto}
					</span>
				</div>
			</div>
		);
	}
}

export default SelectedImgCell;
