import React, { Component } from "react";
import { Link } from "react-router-dom";
import mock_data from "../mock_data.js";
import "../App.css";

class Footer extends Component {
  renderFooterComponent = () => {
    if (this.props.isSplashPage) {
      return null;
    }

    return (
      <header className="nav_container">
        <section className="nav_container_section_1">
          <div>
            <Link to="/film">
              <h1>FILM</h1>
            </Link>
          </div>
          <div>
            <Link to="/images">
              <h1>PHOTOGRAPHY</h1>
            </Link>
          </div>
        </section>
        <section className="nav_container_section_2">
          <div>
            <Link to="/commercial">
              <h1>COMMMERCIAL</h1>
            </Link>
          </div>
        </section>
      </header>
    );
  };

  render() {
    return <div>{this.renderFooterComponent()}</div>;
  }
}

export default Footer;
