import React, { Component } from 'react';
import '../App.css';

class SplashPage extends Component {
	renderSplashPageContent = () => {
		if (!this.props) {
			return null;
		}

		let splashPageData = this.props.splashPageData;

		return (
			<div className="splashpage_main_container">
				<nav>
					<h1>{splashPageData.firstHeadline}</h1>
					<h1>{splashPageData.secondHeadline}</h1>
				</nav>
				<video autoPlay loop muted>
					<source src={splashPageData.videoLink && splashPageData.videoLink.asset.url} type="video/mp4" />
				</video>
			</div>
		);
	};
	render() {
		return <div>{this.renderSplashPageContent()}</div>;
	}
}

export default SplashPage;
